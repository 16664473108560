"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IZumiSwapProvider = void 0;
const chain_1 = require("@zenlink-interface/chain");
const bignumber_1 = require("@ethersproject/bignumber");
const router_config_1 = require("@zenlink-interface/router-config");
const entities_1 = require("../entities");
const util_1 = require("../util");
const abis_1 = require("../abis");
const LiquidityProvider_1 = require("./LiquidityProvider");
class IZumiSwapProvider extends LiquidityProvider_1.LiquidityProvider {
    constructor(chainId, client) {
        super(chainId, client);
        this.SWAP_FEES = [0.0005, 0.003, 0.01];
        this.OFFSET = 500;
        this.BATCH_SIZE = 2000;
        this.poolCodes = [];
        this.initialPools = new Map();
        this.factory = {
            [chain_1.ParachainId.SCROLL_ALPHA]: '0x64c2F1306b4ED3183E7B345158fd01c19C0d8c5E',
            [chain_1.ParachainId.SCROLL]: '0x8c7d3063579BdB0b90997e18A770eaE32E1eBb08',
        };
        this.stateMultiCall = {
            [chain_1.ParachainId.SCROLL_ALPHA]: '0x624303A1B8244ca766458B127c3C70B753891c39',
            [chain_1.ParachainId.SCROLL]: '0x6Bd34eEd9bD2D59c1117cB1578C344E2841897eE',
        };
    }
    async getPools(tokens) {
        if (!(this.chainId in this.factory) || !(this.chainId in this.stateMultiCall)) {
            this.lastUpdateBlock = -1;
            return;
        }
        // tokens deduplication
        const tokenMap = new Map();
        tokens.forEach(t => tokenMap.set((0, util_1.formatAddress)(t.address), t));
        const tokensDedup = Array.from(tokenMap.values());
        // tokens sorting
        const tok0 = tokensDedup.map(t => [(0, util_1.formatAddress)(t.address), t]);
        tokens = tok0.sort((a, b) => (b[0] > a[0] ? -1 : 1)).map(([_, t]) => t);
        const poolsGroup = this.SWAP_FEES.map((swapFee) => {
            const group = [];
            for (let i = 0; i < tokens.length; ++i) {
                const t0 = tokens[i];
                for (let j = i + 1; j < tokens.length; ++j) {
                    const t1 = tokens[j];
                    group.push({ token0: t0, token1: t1, swapFee });
                }
            }
            return group;
        });
        const pools = poolsGroup.flat();
        const poolState = (await Promise.all(poolsGroup.map(pools => this.client.multicall({
            allowFailure: true,
            contracts: pools.map(pool => ({
                args: [
                    this.factory[this.chainId],
                    pool.token0.address,
                    pool.token1.address,
                    pool.swapFee * 1000000,
                    this.OFFSET,
                    this.BATCH_SIZE,
                ],
                address: this.stateMultiCall[this.chainId],
                chainId: chain_1.chainsParachainIdToChainId[this.chainId],
                abi: abis_1.izumiStateMulticall,
                functionName: 'getFullState',
            })),
        }).catch((e) => {
            console.warn(e.message);
            return undefined;
        })))).flat();
        const sameTokensPoolInfos = new Map();
        pools.forEach((pool, i) => {
            if (poolState?.[i]?.status !== 'success' || !poolState?.[i]?.result)
                return;
            const address = poolState[i]?.result?.pool;
            const balance0 = poolState[i]?.result?.balance0;
            const balance1 = poolState[i]?.result?.balance1;
            const pointDelta = poolState[i]?.result?.pointDelta;
            const stateResult = poolState[i]?.result?.state;
            const ordersResult = poolState[i]?.result?.orders;
            if (!address
                || (!balance0 || bignumber_1.BigNumber.from(balance0).lt(BigInt(10 ** pool.token0.decimals)))
                || (!balance1 || bignumber_1.BigNumber.from(balance1).lt(BigInt(10 ** pool.token1.decimals)))
                || !pointDelta
                || !stateResult
                || !ordersResult) {
                return;
            }
            const state = {
                sqrtPriceX96: bignumber_1.BigNumber.from(stateResult.sqrtPrice_96),
                currentPoint: stateResult.currentPoint,
                liquidity: bignumber_1.BigNumber.from(stateResult.liquidity),
                liquidityX: bignumber_1.BigNumber.from(stateResult.liquidityX),
            };
            const orders = {
                liquidity: ordersResult.allLiquidities.map(l => bignumber_1.BigNumber.from(l)),
                liquidityDeltaPoint: ordersResult.allPoint,
                sellingX: ordersResult.sellingX.map(sx => bignumber_1.BigNumber.from(sx)),
                sellingXPoint: ordersResult.sellingXPoint,
                sellingY: ordersResult.sellingY.map(sy => bignumber_1.BigNumber.from(sy)),
                sellingYPoint: ordersResult.sellingYPoint,
            };
            const iziPool = new entities_1.IZiPool(address, pool.token0, pool.token1, pool.swapFee, bignumber_1.BigNumber.from(balance0), bignumber_1.BigNumber.from(balance1), state, pointDelta, orders);
            const poolKey = `${pool.token0.address}-${pool.token1.address}`;
            let sameTokensPoolInfo = sameTokensPoolInfos.get(poolKey);
            if (!sameTokensPoolInfo) {
                sameTokensPoolInfo = {
                    topLiquidity: state.liquidity,
                    sqrtPriceX96: state.sqrtPriceX96,
                    pools: [iziPool],
                };
                sameTokensPoolInfos.set(poolKey, sameTokensPoolInfo);
            }
            else {
                if (state.liquidity.gt(sameTokensPoolInfo.topLiquidity)) {
                    sameTokensPoolInfo.topLiquidity = state.liquidity;
                    sameTokensPoolInfo.sqrtPriceX96 = state.sqrtPriceX96;
                    const poolsCache = [...sameTokensPoolInfo.pools];
                    sameTokensPoolInfo.pools.forEach((pool, i) => {
                        if (!(0, util_1.closeValues)((0, util_1.getNumber)(pool.state.sqrtPriceX96), (0, util_1.getNumber)(state.sqrtPriceX96), 0.1)
                            || !(0, util_1.closeValues)((0, util_1.getNumber)(pool.state.liquidity), (0, util_1.getNumber)(state.liquidity), 0.01)) {
                            poolsCache.splice(i, 1);
                        }
                    });
                    poolsCache.push(iziPool);
                    sameTokensPoolInfo.pools = poolsCache;
                }
                else {
                    if ((0, util_1.closeValues)((0, util_1.getNumber)(sameTokensPoolInfo.sqrtPriceX96), (0, util_1.getNumber)(state.sqrtPriceX96), 0.1)
                        && (0, util_1.closeValues)((0, util_1.getNumber)(sameTokensPoolInfo.topLiquidity), (0, util_1.getNumber)(state.liquidity), 0.01)) {
                        sameTokensPoolInfo.pools.push(iziPool);
                    }
                }
                sameTokensPoolInfos.set(poolKey, sameTokensPoolInfo);
            }
        });
        Array.from(sameTokensPoolInfos.values()).forEach((sameTokensPoolInfo) => {
            sameTokensPoolInfo.pools.forEach((pool) => {
                const pc = new entities_1.IZiPoolCode(pool, this.getPoolProviderName());
                this.poolCodes.push(pc);
                ++this.stateId;
            });
        });
    }
    _getProspectiveTokens(t0, t1) {
        const set = new Set([
            t0,
            t1,
            ...router_config_1.BASES_TO_CHECK_TRADES_AGAINST[this.chainId],
            ...(router_config_1.ADDITIONAL_BASES[this.chainId]?.[t0.address] || []),
            ...(router_config_1.ADDITIONAL_BASES[this.chainId]?.[t1.address] || []),
        ]);
        return Array.from(set);
    }
    startFetchPoolsData() {
        this.stopFetchPoolsData();
        this.poolCodes = [];
        this.getPools(router_config_1.BASES_TO_CHECK_TRADES_AGAINST[this.chainId]); // starting the process
        this.unwatchBlockNumber = this.client.watchBlockNumber({
            onBlockNumber: (blockNumber) => {
                this.lastUpdateBlock = Number(blockNumber);
            },
            onError: (error) => {
                console.error(error.message);
            },
        });
    }
    async fetchPoolsForToken(t0, t1) {
        await this.getPools(this._getProspectiveTokens(t0, t1));
    }
    getCurrentPoolList() {
        return this.poolCodes;
    }
    stopFetchPoolsData() {
        if (this.unwatchBlockNumber)
            this.unwatchBlockNumber();
    }
    getType() {
        return LiquidityProvider_1.LiquidityProviders.Izumiswap;
    }
    getPoolProviderName() {
        return 'Izumiswap';
    }
}
exports.IZumiSwapProvider = IZumiSwapProvider;
